export const getTextStructure = (textField, lineLength, maxRows) => {
  if (textField.includes("\n")) {
    return textField;
  }

  const characters = textField.split("");
  let lines: string[] = [];
  let currentLine = "";

  for (let i = 0; i < characters.length; i++) {
    currentLine += characters[i];

    if (currentLine.length === lineLength) {
      lines.push(currentLine);
      currentLine = "";

      if (lines.length === maxRows) {
        break;
      }
    }
  }

  if (currentLine && lines.length < maxRows) {
    lines.push(currentLine.trim());
  }

  return lines.join("\n");
};
