import { Action, action, persist, thunk, Thunk } from "easy-peasy";
import logging from "../config/logging";
import { StoreModel } from "./index";
import { EDesignStatus } from "../enum/EDesignStatus";
import { IProductDesignSummaryDto } from "../interfaces/DTO/IProductDesignSummaryDto";
import { IProductDesignFilter } from "../interfaces/Filter/IProductDesignFilter";
import { IDesign } from "../interfaces/IDesign";
import { IFrameFold } from "../interfaces/IFrameFold";
import { IIcon } from "../interfaces/IIcon";
import { IIconCategory } from "../interfaces/IIconCategory";
import { EInsertDetailOrder, IInsertDetailRequest } from "../interfaces/IInsertDetail";
import { IInsertType } from "../interfaces/IInsertType";
import { IProject } from "../interfaces/IProject";
import { CompareInsertDetailDTO, IFavoriteDesignCheck } from "../interfaces/IFavoriteDesignCheck";
import { IResult } from "../interfaces/IResult";
import { ICustomDesign, IUserDesignData } from "../interfaces/IUserDesignData";
import { IProductDesignCreateRequest } from "../interfaces/Project/Request/IProductDesignCreateRequest";
import {
  IProductDesignUpdateRequest,
  getProductDesignUpdateRequest,
  userDataToProductDesignRequest,
} from "../interfaces/Project/Request/IProductDesignUpdateRequest";
import { IProjectCreateRequest } from "../interfaces/Project/Request/IProjectCreateRequest";
import { IUpdateProject } from "../interfaces/Project/Request/IUpdateProject";
import {
  IProductDesignResponse,
  IProductFavoriteDesignResponse,
} from "../interfaces/Project/Response/ProductDesignResponse";
import designService from "../services/DesignService";
import frameFoldService from "../services/FrameFoldService";
import iconCategoryService from "../services/IconCategoryService";
import iconService from "../services/IconService";
import insertTypeService from "../services/InsertTypeService";
import { default as productDesignService, default as projectDesignService } from "../services/ProductDesignService";
import projectService from "../services/ProjectService";
import FavoriteDesignsService from "../services/FavoriteDesignsService";
import { handleError } from "../utils/handleError";
import { handleSuccess } from "../utils/handleSuccess";
import { generateRandomProductDesignName } from "../utils/stringHelper";
import { Status } from "./status";
import { IProjectSummaryDto } from "../interfaces/DTO/IProjectSummaryDto";
import { ICoverImageDto } from "../interfaces/DTO/ICoverImageDto";
import { BJE } from "../constants/Theme";
import { ECompany } from "../enum/ECompany";
import { IFilteredFavoriteDesignPayload } from "../interfaces/IFilteredFavoriteDesignsPayload";
import { NUMBER_OF_SINGLE_SWITCH } from "../constants/NumberValues";
import LayoutService from "../services/LayoutService";
import { IDesignDimensions } from "../interfaces/IDesignDimensions";
import { transformData } from "../utils/transformDimensionsData";
import { IProductDesign } from "../interfaces/IProductDesign";
import { IProjectCreateWithFavRequest } from "../interfaces/Project/Request/IProjectCreateWithFavRequest";
import { FRAME_CONFIGURATION_PATH } from "../constants/Paths";

export interface IDesignModel extends IDesignStates, IDesignActions, IDesignThunks {}

interface IDesignStatesConfiguration {
  currentProjectId: string | null;
  currentDesignId: string | null;
  selectedCoverNumberGlobal: number;
  userDesignData: IUserDesignData | null;
}
interface IDesignStatesData {
  productDesigns: IProductDesignResponse[];
  designSizes: IDesignDimensions;
  projects: IProject[];
  projectsSummary: IProjectSummaryDto[];
  designs: IDesign[];
  frameFolds: IFrameFold[];
  insertTypes: IInsertType[];
  icons: IIcon[];
  iconCategories: IIconCategory[];
  productDesignSummary: IProductDesignSummaryDto[];
  productDesignPreview: IProductDesignResponse | null;
  fetchingData: boolean;
  isModifying: boolean;
  fetchingCover: boolean;
  hasSaved: boolean;
  selectedProjectsSummary: string[];
  isActiveNewSetOfIcons: boolean;
  favoriteDesigns: IProductFavoriteDesignResponse[];
  favoriteDesignData: IProductDesignResponse | null;
  selectedProjectsWithProductsIds: string[];
}
interface IDesignStates {
  configuration: IDesignStatesConfiguration;
  data: IDesignStatesData;
}

interface IDesignActions {
  setCurrentProjectId: Action<IDesignModel, string | null>;
  setDesignSizes: Action<IDesignModel, IDesignDimensions>;
  setCurrentDesignId: Action<IDesignModel, string | null>;
  setSelectedCoverNumberGlobal: Action<IDesignModel, number>;
  setUserDesignData: Action<IDesignModel, IUserDesignData | null>;
  setFavoriteDesigns: Action<IDesignModel, IProductFavoriteDesignResponse[]>;
  setFavoriteDesignData: Action<IDesignModel, IProductDesignResponse | null>;
  setUserDesignDataFromProductDesignResponse: Action<IDesignModel, IProductDesignResponse>;
  setUserDesignDataFromDesign: Action<IDesignModel, IDesign>;
  setProductDesigns: Action<IDesignModel, IProductDesignResponse[]>;
  setProjects: Action<IDesignModel, IProject[]>;
  setProjectsSummary: Action<IDesignModel, IProjectSummaryDto[]>;
  setDesigns: Action<IDesignModel, IDesign[]>;
  setFrameFolds: Action<IDesignModel, IFrameFold[]>;
  setInsertTypes: Action<IDesignModel, IInsertType[]>;
  setIcons: Action<IDesignModel, IIcon[]>;
  setIconCategories: Action<IDesignModel, IIconCategory[]>;
  setUserDesignDataInsertDetail: Action<IDesignModel, IProductDesignResponse>;
  setProductDesignSummaryDto: Action<IDesignModel, IProductDesignSummaryDto[]>;
  setProductDesignPreview: Action<IDesignModel, IProductDesignResponse | null>;
  setFetchingData: Action<IDesignModel, boolean>;
  setIsModifying: Action<IDesignModel, boolean>;
  setFetchingCover: Action<IDesignModel, boolean>;
  setHasSaved: Action<IDesignModel, boolean>;
  setSelectedProjectsSummary: Action<IDesignModel, string[]>;
  setIsActiveNewSetOfIcons: Action<IDesignModel, boolean>;
  setSelectedProjectsWithProductsIds: Action<IDesignModel, string[]>;
}

interface IDesignThunks {
  thunkAddFavoriteDesign: Thunk<IDesignModel, IProductDesignResponse>;
  thunkGetDesignSizes: Thunk<IDesignModel, string>;
  thunkGetFavoriteDesign: Thunk<IDesignModel, number>;
  thunkGetFilteredFavoriteDesign: Thunk<IDesignModel, IFilteredFavoriteDesignPayload>;
  thunkGetFavoriteDesignById: Thunk<IDesignModel, string>;
  thunkDeleteFavoriteDesignById: Thunk<IDesignModel, string>;
  thunkCheckFavoriteDesigns: Thunk<IDesignModel, undefined>;
  thunkGetUserDesignDataFromProductDesignResponse: Thunk<IDesignModel, IProductDesignResponse>;
  thunkGetDesigns: Thunk<IDesignModel, number>;
  thunkGetInsertTypes: Thunk<IDesignModel, undefined>;
  thunkGetDesignById: Thunk<IDesignModel, string>;
  thunkGetFrameFolds: Thunk<IDesignModel>;
  thunkGetIcons: Thunk<IDesignModel, undefined>;
  thunkGetIconCategories: Thunk<IDesignModel, undefined>;
  thunkGetProductDesignDataByProjectId: Thunk<
    IDesignModel,
    string,
    undefined,
    {},
    Promise<IResult<IProductDesignResponse[]>>
  >;
  thunkGetProductDesignByProjectId: Thunk<IDesignModel, string>;
  thunkCreateProductDesign: Thunk<IDesignModel, IProductDesignCreateRequest, undefined, {}, Promise<IResult<string>>>;
  thunkCreateProductDesignAsClone: Thunk<
    IDesignModel,
    [IProductDesignCreateRequest, string],
    undefined,
    {},
    Promise<IResult<string>>
  >;
  thunkUpdateProductDesign: Thunk<IDesignModel, IProductDesignUpdateRequest>;
  thunkAddCoverSet: Thunk<IDesignModel, string | undefined>;
  thunkAddCoverSetToNewProject: Thunk<IDesignModel>;
  thunkAddCopiedCoverSet: Thunk<IDesignModel, IProductDesignResponse[]>;
  thunkCloneCover: Thunk<IDesignModel, IUserDesignData, undefined, {}, Promise<IResult<string>>>;
  thunkGetProjectsByUser: Thunk<IDesignModel, undefined>;
  thunkGetProjectsSummary: Thunk<IDesignModel, undefined>;
  thunkCreateProject: Thunk<IDesignModel, IProjectCreateRequest, undefined, {}, Promise<IResult<string>>>;
  thunkUpdateProject: Thunk<IDesignModel, IUpdateProject>;
  thunkDeleteProject: Thunk<IDesignModel, string>;
  thunkDeleteProductDesign: Thunk<IDesignModel, string>;
  thunkAutoSave: Thunk<IDesignModel, IUserDesignData, undefined, {}, Promise<IResult<string>>>;
  thunkGetProductDesignSummaries: Thunk<IDesignModel, IProductDesignFilter>;
  thunkSetCurrentPathName: Thunk<IDesignModel, string>;
  thunkSetCurrentStatus: Thunk<IDesignModel, EDesignStatus>;
  thunkUploadCoverAzStorage: Thunk<IDesignModel, ICoverImageDto[], undefined, {}, Promise<Status>>;
  thunkDeleteCoverAzStorage: Thunk<IDesignModel, string[]>;
  thunkCreateProjectFromFav: Thunk<IDesignModel, IProjectCreateWithFavRequest>;
}

const configuration: IDesignStatesConfiguration = {
  currentProjectId: null,
  currentDesignId: null,
  selectedCoverNumberGlobal: 0,
  userDesignData: null,
};

const data: IDesignStatesData = {
  productDesigns: [],
  designSizes: {},
  projects: [],
  projectsSummary: [],
  designs: [],
  icons: [],
  iconCategories: [],
  frameFolds: [],
  insertTypes: [],
  productDesignSummary: [],
  productDesignPreview: null,
  fetchingData: false,
  isModifying: false,
  fetchingCover: false,
  hasSaved: true,
  selectedProjectsSummary: [],
  isActiveNewSetOfIcons: false,
  favoriteDesigns: [],
  favoriteDesignData: null,
  selectedProjectsWithProductsIds: [],
};

const designsStates: IDesignStates = {
  configuration: persist(configuration, { storage: "localStorage" }),
  data: data,
};

const designsActions: IDesignActions = {
  setDesignSizes: action((state, payload) => {
    state.data.designSizes = payload;
  }),
  setHasSaved: action((state, payload) => {
    state.data.hasSaved = payload;
  }),
  setFetchingCover: action((state, payload) => {
    state.data.fetchingCover = payload;
  }),
  setFetchingData: action((state, payload) => {
    state.data.fetchingData = payload;
  }),
  setIsModifying: action((state, payload) => {
    state.data.isModifying = payload;
  }),
  setCurrentProjectId: action((state, payload) => {
    state.configuration.currentProjectId = payload;
  }),
  setCurrentDesignId: action((state, payload) => {
    state.configuration.currentDesignId = payload;
  }),
  setSelectedCoverNumberGlobal: action((state, payload) => {
    state.configuration.selectedCoverNumberGlobal = payload;
  }),
  setUserDesignData: action((state, payload) => {
    if (payload != null) {
      state.data.hasSaved = false;
    }

    state.configuration.userDesignData = payload;
  }),
  setFavoriteDesigns: action((state, payload) => {
    state.data.favoriteDesigns = payload;
  }),
  setFavoriteDesignData: action((state, payload) => {
    state.data.favoriteDesignData = payload;
  }),
  setUserDesignDataFromProductDesignResponse: action((state, payload) => {
    const current = (
      state.configuration.userDesignData == null ? {} : state.configuration.userDesignData
    ) as IUserDesignData;
    state.configuration.currentDesignId = payload.id;

    state.configuration.userDesignData = Object.assign(current, {
      userCustomDesign: {
        id: payload.id,
        name: payload.name,
        isArchived: payload.isArchived!,
        designId: payload.design?.id!,
        projectId: payload.project?.id,
        frameFold: payload.frameFold != null ? payload.frameFold : null,
        frameColor: payload.frameColor != null ? payload.frameColor : null,
        isHorizontal: payload.isHorizontal,
        pathname: payload.pathname,
        insertDetails: payload.insertDetails,
        quantity: payload.quantity,
        location: payload.location,
        room: payload.room,
        coverImage: payload.coverImage,
      },
      design: payload.design,
      numberOfSwitches: payload.frameFold ? parseInt(payload.frameFold?.frameCode!) : 1,
    });
  }),
  setUserDesignDataInsertDetail: action((state, payload) => {
    state.configuration.userDesignData!.userCustomDesign.insertDetails = payload.insertDetails;
  }),
  setUserDesignDataFromDesign: action((state, payload) => {
    const currentDesignData = (
      state.configuration.userDesignData == null ? {} : state.configuration.userDesignData
    ) as IUserDesignData;

    const currentCustomDesign = (
      state.configuration.userDesignData?.userCustomDesign == null
        ? {}
        : state.configuration.userDesignData?.userCustomDesign
    ) as ICustomDesign;

    state.configuration.userDesignData = Object.assign(currentDesignData, {
      userCustomDesign: Object.assign(currentCustomDesign, {
        designId: payload.id,
        frameColor: payload.frameColor,
        isHorizontal: true,
        room: payload.room,
        location: payload.location,
      }),
      numberOfSwitches: state.configuration.userDesignData?.numberOfSwitches ?? NUMBER_OF_SINGLE_SWITCH,
      design: payload,
    });
  }),
  setProductDesigns: action((state, payload) => {
    state.data.productDesigns = payload;
  }),
  setProjects: action((state, payload) => {
    state.data.projects = payload;
  }),
  setProjectsSummary: action((state, payload) => {
    state.data.projectsSummary = payload;
  }),
  setDesigns: action((state, payload) => {
    state.data.designs = payload;
  }),
  setIcons: action((state, payload) => {
    state.data.icons = payload;
  }),
  setIconCategories: action((state, payload) => {
    state.data.iconCategories = payload;
  }),
  setFrameFolds: action((state, payload) => {
    state.data.frameFolds = payload;
  }),
  setInsertTypes: action((state, payload) => {
    state.data.insertTypes = payload;
  }),
  setProductDesignSummaryDto: action((state, payload) => {
    state.data.productDesignSummary = payload;
  }),
  setProductDesignPreview: action((state, payload) => {
    state.data.productDesignPreview = payload;
  }),
  setSelectedProjectsSummary: action((state, payload) => {
    state.data.selectedProjectsSummary = payload;
  }),
  setIsActiveNewSetOfIcons: action((state, payload) => {
    state.data.isActiveNewSetOfIcons = payload;
  }),
  setSelectedProjectsWithProductsIds: action((state, payload) => {
    state.data.selectedProjectsWithProductsIds = payload;
  }),
};

const designsThunks: IDesignThunks = {
  thunkGetDesignSizes: thunk(async (actions, payload: string) => {
    try {
      const { data } = await LayoutService.getById(payload);
      const frontendStructure = transformData(data);
      actions.setDesignSizes(frontendStructure);
      return Status.COMPLETED;
    } catch (error) {
      console.error("Error fetching design sizes:", error);
      return Status.FAILED;
    }
  }),

  thunkAddFavoriteDesign: thunk(async (actions, payload, { getState }) => {
    try {
      const { userDesignData } = getState().configuration;

      const newValues = {
        ...userDesignData,
        userCustomDesign: {
          coverImage: payload.coverImage,
          comment: payload.comment,
          designId: userDesignData?.userCustomDesign?.designId,
          favoriteDesignId: payload.favoriteDesignId,
          frameColor: payload.frameColor,
          frameFold: payload.frameFold,
          id: payload.id,
          insertDetails: payload.insertDetails,
          isArchived: payload.isArchived,
          isHorizontal: payload.isHorizontal,
          location: payload.location,
          name: payload.name,
          pathname: payload.pathname,
          projectId: payload.project?.id,
          quantity: payload.quantity,
          room: payload.room,
        },
      };

      await actions.thunkAutoSave(newValues);

      return Status.COMPLETED;
    } catch (error) {
      return Status.FAILED;
    }
  }),

  thunkGetFavoriteDesign: thunk(async (actions, payload) => {
    try {
      const { data } = await FavoriteDesignsService.get(payload);
      actions.setFavoriteDesigns(data);

      return data;
    } catch (error) {
      return Status.FAILED;
    }
  }),

  thunkGetFilteredFavoriteDesign: thunk(async (actions, payload) => {
    try {
      const { frameColorId, designId } = payload;

      const { data } = await FavoriteDesignsService.getFilteredFavoriteDesigns(frameColorId, designId);

      return data;
    } catch (error) {
      return Status.FAILED;
    }
  }),

  thunkGetFavoriteDesignById: thunk(async (actions, payload) => {
    try {
      const { data } = await FavoriteDesignsService.getFavoriteDesignById(payload);
      actions.setFavoriteDesignData(data);

      return data;
    } catch (error) {
      return Status.FAILED;
    }
  }),

  thunkDeleteFavoriteDesignById: thunk(async (actions, payload) => {
    try {
      await FavoriteDesignsService.deleteFavoriteDesignById(payload);
      actions.setFavoriteDesignData(null);

      return Status.COMPLETED;
    } catch (error) {
      return Status.FAILED;
    }
  }),

  thunkGetUserDesignDataFromProductDesignResponse: thunk(async (actions, payload, { getState }) => {
    const { userDesignData } = getState().configuration;

    return Object.assign(userDesignData!, {
      userCustomDesign: {
        id: payload.id,
        name: payload.name,
        isArchived: payload.isArchived!,
        designId: payload.design?.id!,
        projectId: payload.project?.id,
        frameFold: payload.frameFold ?? null,
        frameColor: payload.frameColor ?? null,
        isHorizontal: payload.isHorizontal,
        pathname: payload.pathname,
        insertDetails: payload.insertDetails,
        quantity: payload.quantity,
        location: payload.location,
        room: payload.room,
        coverImage: payload.coverImage,
      },
      design: payload.design,
      numberOfSwitches: payload.frameFold ? parseInt(payload.frameFold?.frameCode!) : NUMBER_OF_SINGLE_SWITCH,
    });
  }),
  thunkCheckFavoriteDesigns: thunk(async (actions, payload, { getState, getStoreState }) => {
    const { productDesigns } = getState().data;

    const compareFavoriteDesigns: CompareInsertDetailDTO[] = productDesigns.flatMap((design) =>
      design.insertDetails.map((detail) => ({
        frameColorId: design.frameColor?.id ?? "",
        insertColorId: detail.insertColor?.id ?? "",
        insertTypeId: detail.insertType?.id ?? "",
        insertItemCriteria: (detail.items ?? []).map((item) => ({
          iconId: item.iconId ?? null,
          dimension: item.dimension,
          textValue: item.textValue ?? null,
          fontSize: item.font?.size ?? null,
          itemType: item.type,
          position: {
            position: item.position ?? null,
            positionStart: item.positionStart ?? null,
            positionEnd: item.positionEnd ?? null,
          },
        })),
        rotate: detail.rotate ?? 0,
        isFlipped: detail.isFlipped ?? false,
      }))
    );

    const state = getStoreState() as StoreModel;
    const companyId = process.env.REACT_APP_THEME === BJE ? ECompany.BJE : ECompany.ABB;
    const countryId = state.auth.currentUser?.countryId;
    const dataToCheck: IFavoriteDesignCheck = { compareFavoriteDesigns, companyId, countryId };

    try {
      const { data } = await FavoriteDesignsService.checkFavoriteDesign(dataToCheck);
      const favoriteIds = data.favoriteDesignsIds;

      const foundFavoriteDesigns = productDesigns
        .flatMap((productDesign) =>
          productDesign.insertDetails.map((insertDetail) => ({
            insertDetail,
            productDesignId: productDesign.id,
          }))
        )
        .map(({ insertDetail, productDesignId }, index) => ({
          insertId: insertDetail.id,
          favoriteId: favoriteIds.length ? favoriteIds[index] : null,
          productDesignId,
        }));

      return foundFavoriteDesigns;
    } catch (error) {
      return Status.FAILED;
    }
  }),

  thunkSetCurrentPathName: thunk(async (actions, payload, { getState }) => {
    const { userDesignData } = getState().configuration;

    const newValues = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            pathname: payload,
          },
        },
      },
    };

    actions.setUserDesignData(newValues);
    await actions.thunkAutoSave(newValues);
  }),
  thunkSetCurrentStatus: thunk(async (actions, payload, { getState }) => {
    const { userDesignData } = getState().configuration;

    const newValues = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            designStatus: payload,
          },
        },
      },
    };

    actions.setUserDesignData(newValues);
    actions.thunkAutoSave(newValues);
  }),
  thunkGetProductDesignSummaries: thunk(async (actions, payload) => {
    try {
      actions.setFetchingData(true);
      const response = await productDesignService.get(payload);
      actions.setProductDesignSummaryDto(response.data);
      actions.setFetchingData(false);
      return response.data;
    } catch (error) {
      return Status.FAILED;
    }
  }),
  thunkGetProductDesignDataByProjectId: thunk(async (actions, payload) => {
    try {
      const { data } = await productDesignService.getProductDesignByProjectId(payload);

      actions.setProductDesigns(data);

      const result: IResult<IProductDesignResponse[]> = {
        status: Status.COMPLETED,
        message: "",
        data: data,
      };
      return result;
    } catch (err) {
      logging.error(err);
      const result: IResult<any> = {
        status: Status.FAILED,
        message: "",
        data: err,
      };

      return result;
    } finally {
      actions.setFetchingCover(false);
      actions.setFetchingData(false);
    }
  }),
  thunkCreateProductDesign: thunk(async (actions, payload, { getState, getStoreState }) => {
    try {
      const { data } = await projectDesignService.createProductDesign(payload);

      const { currentProjectId } = getState().configuration;

      await actions.thunkGetProductDesignByProjectId(currentProjectId!);

      const created = getState().data.productDesigns.find((pd) => pd.id == data)!;

      actions.setUserDesignDataFromProductDesignResponse(created);

      actions.setCurrentDesignId(data);

      return handleSuccess<string>();
    } catch (err) {
      return handleError(err);
    }
  }),
  thunkCreateProductDesignAsClone: thunk(
    async (actions, [productDesignRequest, productDesignId], { getState, getStoreState }) => {
      try {
        const { productDesigns } = getState().data;
        const { data: newProjectDesignId } = await projectDesignService.createProductDesign(productDesignRequest);
        const { currentProjectId } = getState().configuration;

        const productDesignToClone = productDesigns.find((productDesign) => productDesign.id === productDesignId);
        await actions.thunkGetDesignSizes(productDesignToClone?.designId as string);
        await actions.thunkGetProductDesignByProjectId(currentProjectId!);

        let createdProductDesign = getState().data.productDesigns.find(
          (productDesign) => productDesign.id == newProjectDesignId
        )!;

        // there is being created project with cloned data without ids (new ids will be created after first PUT /productdesign). For now there is only one, first, insert detail id created
        const insertsWithoutIds =
          productDesignToClone?.insertDetails?.map(({ id, items, ...rest }, index) => ({
            ...rest,
            id: createdProductDesign?.insertDetails[index]?.id ?? undefined,
            items: items?.map(({ id, ...itemRest }) => ({
              ...itemRest,
              insertDetailId: createdProductDesign?.insertDetails[index]?.id,
            })),
            coverDesignId: undefined,
          })) ?? [];

        createdProductDesign.insertDetails = insertsWithoutIds;
        createdProductDesign.frameFold = productDesignToClone?.frameFold;

        actions.setUserDesignDataFromProductDesignResponse(createdProductDesign);

        const { userDesignData } = getState().configuration;

        const newValues = {
          ...userDesignData,
          userCustomDesign: createdProductDesign,
        };

        await actions.thunkAutoSave(newValues);
        actions.setCurrentDesignId(newProjectDesignId);

        return handleSuccess<string>();
      } catch (err) {
        return handleError(err);
      }
    }
  ),
  thunkUpdateProductDesign: thunk(async (actions, payload, { getState, getStoreState }) => {
    try {
      await projectDesignService.updateProductDesign(payload);
      return Status.COMPLETED;
    } catch (err) {
      return Status.FAILED;
    }
  }),
  thunkAddCoverSetToNewProject: thunk(async (actions, payload, { getState, getStoreActions }) => {
    try {
      const { insertTypes, designs, frameFolds } = getState().data;
      const { currentProjectId } = getState().configuration;

      const initialInsertDetail: IInsertDetailRequest = {
        insertTypeId: insertTypes.find((x) => x.name == "Button")?.id ?? insertTypes[0].id,
        insertColorId: designs[0].insertColor.id,
        order: EInsertDetailOrder.First,
        hasGridLine: true,
        items: [],
      };

      const firstDesignId = designs[0].id;

      const payloadProductDesign: IProductDesignCreateRequest = {
        name: generateRandomProductDesignName(),
        projectId: currentProjectId!,
        designId: firstDesignId,
        pathname: "/start-configurator",
        frameColorId: designs[0].frameColor.id,
        frameFoldId: frameFolds[0].id,
        insertDetail: initialInsertDetail,
        isHorizontal: true,
      };

      await actions.thunkCreateProductDesign(payloadProductDesign);

      return handleSuccess();
    } catch (error) {
      return handleError(error);
    }
  }),
  thunkAddCoverSet: thunk(async (actions, payload, { getState }) => {
    try {
      const { insertTypes, designs, frameFolds, productDesigns } = getState().data;
      const { currentProjectId } = getState().configuration;

      const productDesignToBeCopied = payload && productDesigns.find((productDesign) => productDesign.id === payload);

      const productDesignsFromProjectId = productDesigns.filter((x) => x.project?.id == currentProjectId);

      const productDesignsLastModified = productDesignsFromProjectId?.sort(
        (a: any, b: any) => new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime()
      )[0];

      const insertDetailLastModified = productDesignsLastModified?.insertDetails?.sort(
        (a: any, b: any) => a.order - b.order
      )[0];

      const firstDesign = designs[0];

      const initialInsertDetail: IInsertDetailRequest = {
        insertTypeId: insertDetailLastModified?.insertType?.id ?? insertTypes.find((x) => x.name == "Button")?.id,
        insertColorId: insertDetailLastModified?.insertColor?.id ?? firstDesign.insertColor.id,
        order: EInsertDetailOrder.First,
        hasGridLine: true,
        items: [],
      };

      const payloadProductDesign: IProductDesignCreateRequest = {
        name: generateRandomProductDesignName(),
        projectId: currentProjectId!,
        designId: productDesignToBeCopied ? productDesignToBeCopied.designId : productDesignsLastModified?.design?.id,
        pathname: FRAME_CONFIGURATION_PATH,
        frameColorId: productDesignToBeCopied
          ? productDesignToBeCopied.frameColor.id
          : productDesignsLastModified?.frameColor.id,
        frameFoldId: frameFolds[0].id,
        insertDetail: initialInsertDetail,
        isHorizontal: true,
      };

      if (payload) {
        actions.setDesignSizes({});
        await actions.thunkCreateProductDesignAsClone([payloadProductDesign, payload]);
      } else {
        await actions.thunkCreateProductDesign(payloadProductDesign);
      }

      return handleSuccess();
    } catch (error) {
      return handleError(error);
    }
  }),
  thunkAddCopiedCoverSet: thunk(async (actions, payload, { getState }) => {
    try {
      const { productDesigns } = getState().data;
      const { currentProjectId } = getState().configuration;

      productDesigns.forEach(async (design, index) => {
        const designRequest = getProductDesignUpdateRequest(payload[index]);
        const newInsertDetails = designRequest.insertDetails.map((detail, i) => {
          const newDetail = {
            ...detail,
            id: undefined,
            coverDesignId: "",
            items: detail.items.map((item) => {
              return { ...item, id: undefined, insertDetailId: undefined };
            }),
          };
          return newDetail;
        });

        const newDesign = {
          ...designRequest,
          projectId: currentProjectId as string,
          id: design.id as string,
          name: design.name,
          insertDetails: newInsertDetails as IInsertDetailRequest[],
        };
        await actions.thunkUpdateProductDesign(newDesign);
      });

      await actions.thunkGetProjectsSummary();
      return handleSuccess();
    } catch (error) {
      return handleError(error);
    }
  }),
  thunkCloneCover: thunk(async (actions, payload, { getState }) => {
    try {
      const request = userDataToProductDesignRequest(payload);

      let response = await productDesignService.updateProductDesign(request);

      actions.setUserDesignDataFromProductDesignResponse(response.data);
      actions.setUserDesignDataInsertDetail(response.data);

      const result: IResult<string> = {
        status: Status.COMPLETED,
        message: "",
      };

      return result;
    } catch (err) {
      const error = handleError(err);

      const result: IResult<string> = {
        status: Status.FAILED,
        message: error.message,
      };

      return result;
    }
  }),
  thunkGetIcons: thunk(async (actions, payload, { getState, getStoreState }) => {
    try {
      const { data } = await iconService.get();
      actions.setIcons(data);
      return Status.COMPLETED;
    } catch (err) {
      logging.error(err);
      return Status.FAILED;
    }
  }),
  thunkGetIconCategories: thunk(async (actions, payload, { getState, getStoreState }) => {
    try {
      const { data } = await iconCategoryService.get();

      actions.setIconCategories(data);

      return Status.COMPLETED;
    } catch (err) {
      logging.error(err);
      return Status.FAILED;
    }
  }),
  thunkGetDesigns: thunk(async (actions, payload, { getState, getStoreState }) => {
    try {
      const { data } = await designService.get(payload);
      actions.setDesigns(data);

      return Status.COMPLETED;
    } catch (err) {
      logging.error(err);
      return Status.FAILED;
    }
  }),
  thunkGetInsertTypes: thunk(async (actions, payload, { getState, getStoreState }) => {
    try {
      if (getState().data.insertTypes.length == 0) {
        const { data } = await insertTypeService.getAll();
        actions.setInsertTypes(data);
      }

      return Status.COMPLETED;
    } catch (err) {
      logging.error(err);
      return Status.FAILED;
    }
  }),
  thunkGetDesignById: thunk(async (actions, payload, { getState, getStoreState }) => {
    try {
      //const { data } = await designService.getById(payload);
      //actions.setDesign(data);
      return Status.COMPLETED;
    } catch (err) {
      logging.error(err);
      return Status.FAILED;
    }
  }),
  thunkGetFrameFolds: thunk(async (actions, payload, { getState, getStoreState }) => {
    try {
      if (getState().data.frameFolds.length == 0) {
        const { data } = await frameFoldService.getFrameFolds();
        actions.setFrameFolds(data);
      }

      return Status.COMPLETED;
    } catch (err) {
      logging.error(err);
      return Status.FAILED;
    }
  }),
  thunkCreateProject: thunk(async (actions, payload, { getState }) => {
    try {
      const { data } = await projectService.createProject(payload);

      actions.setCurrentProjectId(data.id);

      actions.thunkGetProjectsSummary();

      const result: IResult<string> = {
        status: Status.COMPLETED,
        message: "",
        id: data.id,
      };

      return result;
    } catch (err: any) {
      return handleError(err);
    }
  }),
  thunkUpdateProject: thunk(async (actions, payload, { getState }) => {
    try {
      await actions.thunkGetProjectsSummary();
      const { projectsSummary } = getState().data;
      const duplicatedName = projectsSummary.find(({ name }) => name === payload.name);
      if (duplicatedName) {
        return handleError({ response: { data: "CreateProject_AlreadyExists_Message" } });
      }

      await projectService.update(payload);
      return Status.COMPLETED;
    } catch (error) {
      return handleError(error);
    }
  }),
  thunkDeleteProject: thunk(async (actions, payload) => {
    try {
      const res = await projectService.deleteProject(payload);
      if (res.status !== 200) {
        return Status.FAILED;
      }
      await actions.thunkGetProjectsSummary();
      return Status.COMPLETED;
    } catch (err: any) {
      logging.error(err);

      const error: {
        status: Status;
        message: string;
      } = {
        status: Status.FAILED,
        message: err.response.data,
      };

      return error;
    }
  }),
  thunkGetProjectsSummary: thunk(async (actions, payload, { getState, getStoreState }) => {
    try {
      const state = getStoreState() as StoreModel;
      const countryId = state.auth.currentUser?.countryId;

      const { data } = await projectService.getSummaries(countryId);
      actions.setProjectsSummary(data);

      return Status.COMPLETED;
    } catch (err) {
      logging.error(err);
      return Status.FAILED;
    }
  }),
  thunkGetProductDesignByProjectId: thunk(async (actions, payload, { getState, getStoreState }) => {
    try {
      const { data } = await productDesignService.getProductDesignByProjectId(payload);

      actions.setProductDesigns(data);

      return Status.COMPLETED;
    } catch (err) {
      logging.error(err);
      return Status.FAILED;
    }
  }),
  thunkGetProjectsByUser: thunk(async (actions, payload, { getState, getStoreState }) => {
    try {
      const { data } = await projectService.getProjectsByUserId();
      actions.setProjects(data);

      return Status.COMPLETED;
    } catch (err) {
      logging.error(err);
      return Status.FAILED;
    }
  }),
  thunkDeleteProductDesign: thunk(async (actions, payload, { getState }) => {
    try {
      const res = await productDesignService.deleteProductDesign(payload);
      if (res.status !== 200) {
        return Status.FAILED;
      }
      const { currentProjectId } = getState().configuration;
      await actions.thunkGetProductDesignByProjectId(currentProjectId!);
      actions.setProductDesignSummaryDto(res.data);
      return Status.COMPLETED;
    } catch (err) {
      logging.error(err);
      return Status.FAILED;
    }
  }),
  thunkAutoSave: thunk(async (actions, payload, { getState }) => {
    try {
      actions.setFetchingData(true);

      const request = userDataToProductDesignRequest(payload);

      let response = await productDesignService.updateProductDesign(request);

      actions.setUserDesignDataInsertDetail(response.data);
      actions.setHasSaved(true);
      await actions.thunkGetProductDesignByProjectId(response.data.project.id);

      return handleSuccess<string>();
    } catch (err) {
      return handleError(err);
    } finally {
      actions.setFetchingData(false);
      actions.setFetchingCover(false);
    }
  }),
  thunkUploadCoverAzStorage: thunk(async (actions, payload, { getState }) => {
    try {
      await productDesignService.uploadCoverAzStorage(payload);
      return Status.COMPLETED;
    } catch (err) {
      return Status.FAILED;
    } finally {
    }
  }),
  thunkDeleteCoverAzStorage: thunk(async (actions, payload, { getState }) => {
    try {
      await productDesignService.deleteCoverAzStorage(payload);
    } catch (err) {
      return handleError(err);
    } finally {
    }
  }),
  thunkCreateProjectFromFav: thunk(async (actions, payload) => {
    const res = await projectService.createProjectWithFavouriteId(payload);
    const mapResToUserCustomDesign = (data: IProductDesign): IUserDesignData => ({
      design: data.design,
      numberOfSwitches: NUMBER_OF_SINGLE_SWITCH,
      userCustomDesign: {
        id: data.id,
        name: data.name,
        room: data.room,
        designId: data.designId,
        projectId: data.project.id,
        code: data.design.code,
        designStatus: data.designStatus,
        comment: data.comment,
        favoriteDesignId: data.favoriteDesignId,
        frameColor: data.frameColor,
        frameFold: data.frameFold,
        coverImage: data.coverImage,
        insertDetails: data.insertDetails,
        isArchived: data.isArchived,
        isHorizontal: data.isHorizontal,
        pathname: data.pathname,
        location: data.location,
        quantity: data.quantity,
      },
    });
    await actions.setUserDesignData(mapResToUserCustomDesign(res.data));
    if (res.data.project.id) {
      await actions.setCurrentProjectId(res.data.project.id);
    }
    return actions.thunkGetProjectsSummary();
  }),
};

const designs: IDesignModel = Object.assign(designsStates, designsActions, designsThunks);

export default designs;
