import { Grid, IconButton, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { useStoreState } from "../../../../hooks";
import { EInsertDetailItemType, IInsertDetail } from "../../../../interfaces/IInsertDetail";
import { ITileDimension } from "../../../../interfaces/ITileDimension";
import { IRockerTextLabelProps, isHorizontal, ITextLabelSizes, LimitChar } from "./limitChar";
import { ABB } from "../../../../constants/Theme";
import { FTSTheme } from "../../../../App";
import EditIcon from "../../Icons/EditIcon.svg";
import CoverItemsFunctions from "./utils-cover-items";
import { getTextStructure } from "./utils-get-text-structure";
import { ONE_BUTTON_ONE_ROCKER, ROCKER, TWO_BUTTONS_ONE_ROCKER, TWO_ROCKERS } from "../../../../constants/InsertType";
import {
  MAX_CHARS_PER_LINE_FOR_ROTATED_ROCKER,
  MAX_CHARS_PER_LINE_FOR_ROTATED_ROCKER_FRAME_62,
  MAX_ROWS_FOR_ROCKER,
  NO_ROTATION_0_DEGREE,
  ROTATION_180_DEGREE,
  ROTATION_270_DEGREE,
  ROTATION_90_DEGREE,
} from "../../../../constants/NumberValues";
import { FRAME_62 } from "../../../../constants/DesignTypes";

interface ITilePreviewConfigurationProps {
  cover: number;
  insertDetail: IInsertDetail;
  indexDetail: string;
  isRocker: boolean;
  tileDimension: ITileDimension;
  tileName?: string;
  textLabelSizes?: ITextLabelSizes | IRockerTextLabelProps;
  designCode?: string;
}

const pxINmm = 3.7795275591;

const TilePreviewConfiguration: React.FC<ITilePreviewConfigurationProps> = (props) => {
  const theme = useTheme() as FTSTheme;
  const { icons } = useStoreState((state) => state.designs.data);

  const [insertDetail, setInsertDetail] = useState<IInsertDetail>(props.insertDetail);

  useEffect(() => {
    CoverItemsFunctions.initialItems(props.insertDetail, setInsertDetail);
  }, []);

  const icon = icons.find(
    (x) =>
      x.id ===
      props.insertDetail.items.find(
        (x) => x.type == EInsertDetailItemType.Icon && x.indexDetail.toString() === props.indexDetail
      )?.iconId
  );

  const currentItem = props.insertDetail.items.find((item) => item.indexDetail.toString() === props.indexDetail);
  const withoutIcon =
    currentItem?.type === EInsertDetailItemType.Text &&
    !props.insertDetail.items.some(
      (item) => item.type === EInsertDetailItemType.Icon && item.indexDetail.toString() === props.indexDetail
    );

  const textStyle = `tile-div-canvas ${
    withoutIcon
      ? "without-icon"
      : props.tileDimension.isFunction
        ? "function"
        : props.isRocker
          ? "button-rocker"
          : "default"
  }`;

  const iconSize =
    props.insertDetail.items.find(
      (icon) => icon.type == EInsertDetailItemType.Icon && icon.indexDetail.toString() === props.indexDetail
    )?.dimension?.width! * pxINmm;

  const iconColor = props.tileDimension.isFunction
    ? insertDetail?.insertColor?.secondaryPrintColor?.hexColor!
    : insertDetail?.insertColor?.primaryPrintColor?.hexColor!;

  const textField =
    props.insertDetail.items.find(
      (item) => item.type == EInsertDetailItemType.Text && item.indexDetail.toString() === props.indexDetail
    )?.textValue ?? "";

  const fontSize = insertDetail.items.find(
    (item) => item.type == EInsertDetailItemType.Text && item.indexDetail.toString() === props.indexDetail
  )?.font?.size;

  const getRotate = () => {
    const rotate = props.insertDetail.rotate ?? 0;

    if (rotate == 90) {
      return 270;
    }
    if (rotate == 180) {
      return 180;
    }
    if (rotate == 270) {
      return 90;
    }

    return 0;
  };

  const getNewWidth = (insertDetail: IInsertDetail, newRotate?: number) => {
    const textFieldProps = LimitChar.get(props.designCode!)!;
    if (newRotate == null && (insertDetail.rotate == null || insertDetail.rotate == 0))
      return props.textLabelSizes!.canvas?.width;

    const rotate = newRotate ?? insertDetail.rotate!;

    if (props.isRocker) {
      return isHorizontal(rotate)
        ? textFieldProps.horizontal[props.tileName!].rocker.canvas.width
        : textFieldProps.vertical[props.tileName!].rocker.canvas.width;
    }

    return isHorizontal(rotate)
      ? textFieldProps.horizontal[props.tileName!].canvas.width
      : textFieldProps.vertical[props.tileName!].canvas.width;
  };

  const layoutsToShorten = [ROCKER, TWO_ROCKERS, ONE_BUTTON_ONE_ROCKER];

  const isRotated90or270 = insertDetail.rotate === ROTATION_90_DEGREE || insertDetail.rotate === ROTATION_270_DEGREE;

  const isLayoutToShorten = layoutsToShorten.includes(insertDetail.insertType?.name as string);

  const isTwoButtonsOneRockerWithNoOr180Rotation =
    insertDetail.insertType?.name === TWO_BUTTONS_ONE_ROCKER &&
    (insertDetail.rotate === NO_ROTATION_0_DEGREE || insertDetail.rotate === ROTATION_180_DEGREE);

  const shouldBeShorten = (isRotated90or270 && isLayoutToShorten) || isTwoButtonsOneRockerWithNoOr180Rotation;

  const structuredTextField = shouldBeShorten
    ? getTextStructure(
        textField,
        FRAME_62.includes(props.designCode as string)
          ? MAX_CHARS_PER_LINE_FOR_ROTATED_ROCKER_FRAME_62
          : MAX_CHARS_PER_LINE_FOR_ROTATED_ROCKER,
        MAX_ROWS_FOR_ROCKER
      )
    : textField;

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          height: "100%",
        }}
      >
        <div
          className="box-icon"
          style={{
            fontFamily: `${theme.name == ABB ? "ABBvoice" : "BJEAverta"}`,
            transform: `rotate(${getRotate()}deg)`,
            width: `${getNewWidth(props.insertDetail, props.insertDetail.rotate!)}px`,
          }}
        >
          {icon?.blobUrl && (
            <IconButton
              disabled
              className={`${
                props.tileDimension.isFunction
                  ? `${theme.name}-border-icon-function-with-canvas-preview`
                  : `${theme.name}-border-icon-control-with-canvas-preview`
              }`}
            >
              <ReactSVG
                src={icon?.blobUrl ?? EditIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute(
                    "style",
                    `width: ${iconSize}px; height: ${iconSize}px; fill: ${
                      icon?.blobUrl ? iconColor : "transparent"
                    }; display: flex;`
                  );
                }}
              />
            </IconButton>
          )}

          <div
            className={textStyle}
            style={{
              color: props.tileDimension.isFunction
                ? insertDetail?.insertColor?.secondaryPrintColor?.hexColor!
                : insertDetail?.insertColor?.primaryPrintColor?.hexColor!,
              fontSize: `${fontSize}pt`,
              whiteSpace: "pre",
              textAlign: "center",
              lineHeight: `${fontSize! + 3}pt`,
              fontWeight: "normal",
              height: `${fontSize! * structuredTextField?.split("\n").length! + (structuredTextField?.split("\n").length! == 1 ? 4 : 6)}px`,
              width: `${getNewWidth(props.insertDetail, props.insertDetail.rotate!)}px`,
              display: "flex",
              position: "absolute",
              justifyContent: "center",
            }}
          >
            {structuredTextField}
          </div>
        </div>
      </Grid>
    </>
  );
};

export default TilePreviewConfiguration;
