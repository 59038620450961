import { useState } from "react";
import { useStoreActions } from "../../../../hooks";
import { useNavigate } from "react-router-dom";
import { IconButton, Menu, MenuItem, Button, Typography } from "@mui/material";
import { ReactSVG } from "react-svg";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import EditIcon from "../../Icons/EditIcon.svg";
import TrashIcon from "../../Icons/TrashIcon.svg";
import { EDesignStatus } from "../../../../enum/EDesignStatus";
import { Status } from "../../../../models/status";
import { useTranslation } from "react-i18next";
import { INSERT_CONFIGURATION_PATH } from "../../../../constants/Paths";
import { handleError } from "../../../../utils/handleError";
import { handleSuccess } from "../../../../utils/handleSuccess";

interface IActionsProps {
  productDesignId: string;
  designStatus: EDesignStatus;
  projectId: string;
  refeshScreen: () => void;
}

const Actions: React.FC<IActionsProps> = (props) => {
  const { t } = useTranslation();
  const navigate: any = useNavigate();

  const { thunkDeleteProductDesign, thunkAddCoverSet, setUserDesignData, setCurrentProjectId } = useStoreActions(
    (actions) => actions.designs
  );

  const { thunkOpenSnackBar, thunkOpenConfirm, thunkCloseConfirm } = useStoreActions((actions) => actions.app);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const handleCloneModify = async (productDesignId: string) => {
    try {
      setUserDesignData(null);
      setCurrentProjectId(props.projectId);

      await thunkAddCoverSet(productDesignId);

      navigate(INSERT_CONFIGURATION_PATH, {
        state: {
          isDesignSelected: true,
        },
      });
      handleCloseOptions();
      return handleSuccess(t("design_summary.helper_text.add_cover_set.success_message"));
    } catch (error) {
      return handleError(error);
    }
  };

  const handleDeleteProductDesign = (productDesignId: string) => {
    thunkOpenConfirm({
      title: t("DesignSummaryDialog_Delete_Title"),
      message: t("DesignSummaryDialog_Delete_Message"),
      useLoading: true,
      onDismiss: {
        text: t("DesignSummaryDialog_CancelButton_Text"),
      },
      onConfirm: {
        text: t("DesignSummaryDialog_ConfirmButton_Text"),
        onClick: () => handleSubmitDeleteProductDesign(productDesignId),
        icon: (
          <ReactSVG
            src={TrashIcon}
            beforeInjection={(svg) => {
              svg.setAttribute("style", "fill: #FFFFFF !important");
            }}
          />
        ),
      },
    });
    handleCloseOptions();
  };

  const handleSubmitDeleteProductDesign = async (productDesignId: string) => {
    const result = await thunkDeleteProductDesign(productDesignId);

    if (result === Status.FAILED) {
      thunkOpenSnackBar({
        message: t("DesignSummaryDialog_DeleteError_Message"),
        severity: `error`,
      });
      thunkCloseConfirm();
      return;
    }

    thunkOpenSnackBar({
      message: t("DesignSummaryDialog_DeleteSuccess_Message"),
      severity: `success`,
    });
    props.refeshScreen();
    thunkCloseConfirm();
  };

  return (
    <>
      <IconButton
        style={{ color: "#696969" }}
        onClick={handleOpenOptions}
      >
        <MoreVertOutlinedIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseOptions}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {props.designStatus === EDesignStatus.Completed && (
          <MenuItem onClick={() => handleCloneModify(props.productDesignId)}>
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: 400,
                color: "#000000 !important",
                textTransform: "capitalize",
              }}>
              <ReactSVG
                src={EditIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute("style", "width: 16px; height: 16px;");
                }}
              />
            </Typography>
            <Typography sx={{marginLeft: "10px"}}>
              {t("DesignSummary_clone_modify")}
            </Typography>
          </MenuItem>
        )}
        <MenuItem onClick={() => handleDeleteProductDesign(props.productDesignId)}>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: 400,
              color: "#000000 !important",
            }}>
            <ReactSVG
              src={TrashIcon}
              beforeInjection={(svg) => {
                svg.setAttribute("style", "width: 16px; height: 16px; fill: black");
              }}
            />
          </Typography>
          <Typography sx={{marginLeft: "10px"}}>
            {t("DesignSummary_Delete")}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Actions;
