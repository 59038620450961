export interface ITextFieldProps {
  horizontal: IOrientationProps;
  vertical: IOrientationProps;
}

interface IOrientationProps {
  oneButton: ITextLabelSizes;
  rocker: ITextLabelSizes;
  twoButtons: ITextLabelSizes;
  twoRockers: ITextLabelSizes;
  oneButtonOneRocker: ITextLabelSizes;
  twoButtonsOneRocker: ITextLabelSizes;
  oneSlider: ITextLabelSizes;
  twoSliders: ITextLabelSizes;
  oneSliderTwoButtons: ITextLabelSizes;
  oneSliderOneRocker: ITextLabelSizes;
  oneSliderOneButton: ITextLabelSizes;
  fourButtons: ITextLabelSizes;
}

export interface ITextLabelSizes {
  rocker?: IRockerTextLabelProps;
  small: ITextLabelProps;
  standard: ITextLabelProps;
  medium: ITextLabelProps;
  large: ITextLabelProps;
  xlarge: ITextLabelProps;
  canvas: ICanvasProps;
}

export interface ICanvasProps {
  width: number;
}

export interface IRockerTextLabelProps {
  small: ITextLabelProps;
  standard: ITextLabelProps;
  medium: ITextLabelProps;
  large: ITextLabelProps;
  xlarge: ITextLabelProps;
  canvas?: ICanvasProps;
}

export interface ITextLabelProps {
  maxRows: number;
}

const smallCoverSizes = (() => {
  const size = 207.87401575;
  const padding = 12;
  const sizeWithoutPadding = size - 2 * padding;
  const separationLineWidth = 1;
  const spaceForSeparationLine = 2;
  const oneRockerSpaceBetweenTiles = 1;
  const twoRockersSpaceBetweenTiles = 1;
  const oneButtonOneRockerSpaceBetweenTiles = 1;
  const twoButtonsOneRockerSpaceBetweenTiles = 1;
  const tileBorder = 2;

  return {
    size,
    padding,
    sizeWithoutPadding,
    separationLineWidth,
    spaceForSeparationLine,
    oneRockerSpaceBetweenTiles,
    twoRockersSpaceBetweenTiles,
    oneButtonOneRockerSpaceBetweenTiles,
    twoButtonsOneRockerSpaceBetweenTiles,
    tileBorder,
  };
})();

const standardCoverSizes = (() => {
  const size = 238.11023;
  const padding = 12;
  const sizeWithoutPadding = size - 2 * padding;
  const separationLineWidth = 1;
  const spaceForSeparationLine = 2;
  const oneRockerSpaceBetweenTiles = 2;
  const twoRockersSpaceBetweenTiles = 1;
  const oneButtonOneRockerSpaceBetweenTiles = 1;
  const twoButtonsOneRockerSpaceBetweenTiles = 1;
  const tileBorder = 2;

  return {
    size,
    padding,
    sizeWithoutPadding,
    separationLineWidth,
    spaceForSeparationLine,
    oneRockerSpaceBetweenTiles,
    twoRockersSpaceBetweenTiles,
    oneButtonOneRockerSpaceBetweenTiles,
    twoButtonsOneRockerSpaceBetweenTiles,
    tileBorder,
  };
})();

const CoverSizes54 = (() => {
  const size = 204.09448819;
  const padding = 12;
  const sizeWithoutPadding = size - 2 * padding;
  const separationLineWidth = 2;
  const spaceForSeparationLine = 4;
  const oneRockerSpaceBetweenTiles = 2;
  const twoRockersSpaceBetweenTiles = 2;
  const oneButtonOneRockerSpaceBetweenTiles = 2;
  const twoButtonsOneRockerSpaceBetweenTiles = 2;
  const tileBorder = 1;

  return {
    size,
    padding,
    sizeWithoutPadding,
    separationLineWidth,
    spaceForSeparationLine,
    oneRockerSpaceBetweenTiles,
    twoRockersSpaceBetweenTiles,
    oneButtonOneRockerSpaceBetweenTiles,
    twoButtonsOneRockerSpaceBetweenTiles,
    tileBorder,
  };
})();

const CoverSizes59 = (() => {
  const size = 225.63779528;
  const padding = 27.779527559;
  const sizeWithoutPadding = size - 2 * padding;
  const separationLineWidth = 1;
  const spaceForSeparationLine = 2;
  const oneRockerSpaceBetweenTiles = 1;
  const twoRockersSpaceBetweenTiles = 1;
  const oneButtonOneRockerSpaceBetweenTiles = 1;
  const twoButtonsOneRockerSpaceBetweenTiles = 1;
  const tileBorder = 2;

  return {
    size,
    padding,
    sizeWithoutPadding,
    separationLineWidth,
    spaceForSeparationLine,
    oneRockerSpaceBetweenTiles,
    twoRockersSpaceBetweenTiles,
    oneButtonOneRockerSpaceBetweenTiles,
    twoButtonsOneRockerSpaceBetweenTiles,
    tileBorder,
  };
})();

const CoverSizes70 = (() => {
  const size = 262.67716535;
  const padding = 38.4;
  const sizeWithoutPadding = size - 2 * padding;
  const separationLineWidth = 1;
  const spaceForSeparationLine = 2;
  const oneRockerSpaceBetweenTiles = 1;
  const twoRockersSpaceBetweenTiles = 1;
  const oneButtonOneRockerSpaceBetweenTiles = 1;
  const twoButtonsOneRockerSpaceBetweenTiles = 1;
  const tileBorder = 2;

  return {
    size,
    padding,
    sizeWithoutPadding,
    separationLineWidth,
    spaceForSeparationLine,
    oneRockerSpaceBetweenTiles,
    twoRockersSpaceBetweenTiles,
    oneButtonOneRockerSpaceBetweenTiles,
    twoButtonsOneRockerSpaceBetweenTiles,
    tileBorder,
  };
})();

const smallLimitChar: ITextFieldProps = {
  horizontal: {
    oneButton: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 2 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: smallCoverSizes.sizeWithoutPadding - smallCoverSizes.tileBorder,
      },
    },
    rocker: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.oneRockerSpaceBetweenTiles * 2) / 3 -
            smallCoverSizes.tileBorder,
        },
      },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.oneRockerSpaceBetweenTiles * 2) / 3 -
          smallCoverSizes.tileBorder,
      },
    },
    twoButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: smallCoverSizes.sizeWithoutPadding - smallCoverSizes.tileBorder,
      },
    },
    twoRockers: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.twoRockersSpaceBetweenTiles * 2) / 3 -
            smallCoverSizes.tileBorder,
        },
      },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.twoRockersSpaceBetweenTiles * 2) / 3 -
          smallCoverSizes.tileBorder,
      },
    },
    oneButtonOneRocker: {
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.twoButtonsOneRockerSpaceBetweenTiles * 2) / 3 -
            smallCoverSizes.tileBorder,
        },
      },
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
          smallCoverSizes.tileBorder,
      },
    },
    twoButtonsOneRocker: {
      small: { maxRows: 2 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
            smallCoverSizes.tileBorder,
        },
      },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
          smallCoverSizes.tileBorder,
      },
    },
    oneSlider: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: smallCoverSizes.sizeWithoutPadding - smallCoverSizes.tileBorder,
      },
    },
    oneSliderOneButton: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
          smallCoverSizes.tileBorder,
      },
    },
    twoSliders: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
          smallCoverSizes.tileBorder,
      },
    },
    oneSliderTwoButtons: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
          smallCoverSizes.tileBorder,
      },
    },
    oneSliderOneRocker: {
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
            smallCoverSizes.tileBorder,
        },
      },
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
          smallCoverSizes.tileBorder,
      },
    },
    fourButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
          smallCoverSizes.tileBorder,
      },
    },
  },
  vertical: {
    oneButton: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 2 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: smallCoverSizes.sizeWithoutPadding - smallCoverSizes.tileBorder,
      },
    },
    rocker: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: smallCoverSizes.sizeWithoutPadding - smallCoverSizes.tileBorder, //198
        },
      },
      canvas: {
        width: smallCoverSizes.sizeWithoutPadding - smallCoverSizes.tileBorder,
      },
    },
    twoButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
          smallCoverSizes.tileBorder, //96.8
      },
    },
    twoRockers: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
            smallCoverSizes.tileBorder,
        },
      },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
          smallCoverSizes.tileBorder,
      },
    },
    oneButtonOneRocker: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: smallCoverSizes.sizeWithoutPadding - smallCoverSizes.tileBorder,
      },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
            smallCoverSizes.tileBorder,
        },
      },
    },
    twoButtonsOneRocker: {
      small: { maxRows: 2 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.twoButtonsOneRockerSpaceBetweenTiles * 2) / 3 -
            smallCoverSizes.tileBorder,
        },
      },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
          smallCoverSizes.tileBorder,
      },
    },
    oneSlider: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: smallCoverSizes.sizeWithoutPadding - smallCoverSizes.tileBorder,
      },
    },
    oneSliderOneButton: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
          smallCoverSizes.tileBorder,
      },
    },
    twoSliders: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
          smallCoverSizes.tileBorder,
      },
    },
    oneSliderTwoButtons: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
          smallCoverSizes.tileBorder,
      },
    },
    oneSliderOneRocker: {
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
            smallCoverSizes.tileBorder,
        },
      },
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
          smallCoverSizes.tileBorder,
      },
    },
    fourButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width:
          (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2 -
          smallCoverSizes.tileBorder,
      },
    },
  },
};

const LimitChar62: ITextFieldProps = {
  horizontal: {
    //vertical in excel
    oneButton: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 2 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: standardCoverSizes.sizeWithoutPadding - standardCoverSizes.tileBorder,
      },
    },
    rocker: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.oneRockerSpaceBetweenTiles * 2) / 3 -
            standardCoverSizes.tileBorder,
        },
      },
      canvas: {
        width: standardCoverSizes.sizeWithoutPadding - standardCoverSizes.tileBorder,
      },
    },
    twoButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: standardCoverSizes.sizeWithoutPadding - standardCoverSizes.tileBorder,
      },
    },
    twoRockers: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.twoRockersSpaceBetweenTiles * 2) / 3 -
            standardCoverSizes.tileBorder,
        },
      },
      canvas: {
        width:
          (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.twoRockersSpaceBetweenTiles * 2) / 3 -
          standardCoverSizes.tileBorder,
      },
    },
    oneButtonOneRocker: {
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.twoButtonsOneRockerSpaceBetweenTiles * 2) / 3 -
            standardCoverSizes.tileBorder,
        },
      },
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width:
          (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2 -
          standardCoverSizes.tileBorder,
      },
    },
    twoButtonsOneRocker: {
      small: { maxRows: 2 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2 -
            -standardCoverSizes.tileBorder,
        },
      },
      canvas: {
        width:
          (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2 -
          standardCoverSizes.tileBorder,
      },
    },
    oneSlider: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2,
      },
    },
    oneSliderOneButton: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2,
      },
    },
    twoSliders: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2,
      },
    },
    oneSliderTwoButtons: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (smallCoverSizes.sizeWithoutPadding - smallCoverSizes.spaceForSeparationLine) / 2,
      },
    },
    oneSliderOneRocker: {
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2,
        },
      },
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2,
      },
    },
    fourButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2,
      },
    },
  },
  vertical: {
    //horizontal in excel
    oneButton: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 2 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: standardCoverSizes.sizeWithoutPadding - standardCoverSizes.tileBorder,
      },
    },
    rocker: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: standardCoverSizes.sizeWithoutPadding - standardCoverSizes.tileBorder,
        },
      },
      canvas: {
        width: standardCoverSizes.sizeWithoutPadding - standardCoverSizes.tileBorder,
      },
    },
    twoButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width:
          (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2 -
          standardCoverSizes.tileBorder,
      },
    },
    twoRockers: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2 -
            standardCoverSizes.tileBorder,
        },
      },
      canvas: {
        width:
          (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2 -
          standardCoverSizes.tileBorder,
      },
    },
    oneButtonOneRocker: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2 -
            -standardCoverSizes.tileBorder,
        },
      },
      canvas: {
        width: standardCoverSizes.sizeWithoutPadding - standardCoverSizes.tileBorder,
      },
    },
    twoButtonsOneRocker: {
      small: { maxRows: 2 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.twoButtonsOneRockerSpaceBetweenTiles * 2) / 3 -
            standardCoverSizes.tileBorder,
        },
      },
      canvas: {
        width:
          (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2 -
          standardCoverSizes.tileBorder,
      },
    },
    oneSlider: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: standardCoverSizes.sizeWithoutPadding - standardCoverSizes.tileBorder,
      },
    },
    oneSliderOneButton: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width:
          (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2 -
          standardCoverSizes.tileBorder,
      },
    },
    twoSliders: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width:
          (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2 -
          standardCoverSizes.tileBorder,
      },
    },
    oneSliderTwoButtons: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width:
          (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2 -
          standardCoverSizes.tileBorder,
      },
    },
    oneSliderOneRocker: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width:
          (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2 -
          standardCoverSizes.tileBorder,
      },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2 -
            standardCoverSizes.tileBorder,
        },
      },
    },
    fourButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width:
          (standardCoverSizes.sizeWithoutPadding - standardCoverSizes.spaceForSeparationLine) / 2 -
          standardCoverSizes.tileBorder,
      },
    },
  },
};

const LimitChar54: ITextFieldProps = {
  horizontal: {
    //vertical in excel
    oneButton: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 2 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: CoverSizes54.sizeWithoutPadding - CoverSizes54.tileBorder * 2,
      },
    },
    rocker: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (CoverSizes54.sizeWithoutPadding - CoverSizes54.oneRockerSpaceBetweenTiles * 2) / 3 -
            CoverSizes54.tileBorder,
        },
      },
      canvas: {
        width:
          (CoverSizes54.sizeWithoutPadding - CoverSizes54.oneRockerSpaceBetweenTiles * 2) / 3 - CoverSizes54.tileBorder,
      },
    },
    twoButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: CoverSizes54.sizeWithoutPadding - CoverSizes54.tileBorder,
      },
    },
    twoRockers: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (CoverSizes54.sizeWithoutPadding - CoverSizes54.twoRockersSpaceBetweenTiles * 2) / 3 -
            CoverSizes54.tileBorder,
        },
      },
      canvas: {
        width:
          (CoverSizes54.sizeWithoutPadding - CoverSizes54.twoRockersSpaceBetweenTiles * 2) / 3 -
          CoverSizes54.tileBorder,
      },
    },
    oneButtonOneRocker: {
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (CoverSizes54.sizeWithoutPadding - CoverSizes54.twoButtonsOneRockerSpaceBetweenTiles * 2) / 3 -
            CoverSizes54.tileBorder,
        },
      },
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
      },
    },
    twoButtonsOneRocker: {
      small: { maxRows: 2 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
        },
      },
      canvas: {
        width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
      },
    },
    oneSlider: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
      },
    },
    oneSliderOneButton: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
      },
    },
    twoSliders: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
      },
    },
    oneSliderTwoButtons: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
      },
    },
    oneSliderOneRocker: {
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
        },
      },
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
      },
    },
    fourButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
      },
    },
  },
  vertical: {
    //horizontal in excel
    oneButton: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 2 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: CoverSizes54.sizeWithoutPadding - CoverSizes54.tileBorder * 2,
      },
    },
    rocker: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: CoverSizes54.sizeWithoutPadding - CoverSizes54.tileBorder,
        },
      },
      canvas: {
        width: CoverSizes54.sizeWithoutPadding - CoverSizes54.tileBorder,
      },
    },
    twoButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder, //96.8
      },
    },
    twoRockers: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
        },
      },
      canvas: {
        width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
      },
    },
    oneButtonOneRocker: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
        },
      },
      canvas: {
        width: CoverSizes54.sizeWithoutPadding - CoverSizes54.tileBorder,
      },
    },
    twoButtonsOneRocker: {
      small: { maxRows: 2 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (CoverSizes54.sizeWithoutPadding - CoverSizes54.twoButtonsOneRockerSpaceBetweenTiles * 2) / 3 -
            CoverSizes54.tileBorder,
        },
      },
      canvas: {
        width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
      },
    },
    oneSlider: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: CoverSizes54.sizeWithoutPadding - CoverSizes54.tileBorder,
      },
    },
    oneSliderOneButton: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder, //96.8
      },
    },
    twoSliders: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
      },
    },
    oneSliderTwoButtons: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
      },
    },
    oneSliderOneRocker: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width:
          (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder * 1,
      },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
        },
      },
    },
    fourButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: (CoverSizes54.sizeWithoutPadding - CoverSizes54.spaceForSeparationLine) / 2 - CoverSizes54.tileBorder,
      },
    },
  },
};

const LimitChar59: ITextFieldProps = {
  horizontal: {
    oneButton: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 2 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: CoverSizes59.sizeWithoutPadding - CoverSizes59.tileBorder,
      },
    },
    rocker: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (CoverSizes59.sizeWithoutPadding - CoverSizes59.oneRockerSpaceBetweenTiles * 2) / 3 -
            CoverSizes59.tileBorder,
        },
      },
      canvas: {
        width:
          (CoverSizes59.sizeWithoutPadding - CoverSizes59.oneRockerSpaceBetweenTiles * 2) / 3 - CoverSizes59.tileBorder,
      },
    },
    twoButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: CoverSizes59.sizeWithoutPadding - CoverSizes59.tileBorder,
      },
    },
    twoRockers: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (CoverSizes59.sizeWithoutPadding - CoverSizes59.twoRockersSpaceBetweenTiles * 2) / 3 -
            CoverSizes59.tileBorder,
        },
      },
      canvas: {
        width:
          (CoverSizes59.sizeWithoutPadding - CoverSizes59.twoRockersSpaceBetweenTiles * 2) / 3 -
          CoverSizes59.tileBorder,
      },
    },
    oneButtonOneRocker: {
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (CoverSizes59.sizeWithoutPadding - CoverSizes59.twoButtonsOneRockerSpaceBetweenTiles * 2) / 3 -
            CoverSizes59.tileBorder,
        },
      },
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
      },
    },
    twoButtonsOneRocker: {
      small: { maxRows: 2 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
        },
      },
      canvas: {
        width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
      },
    },
    oneSlider: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: CoverSizes59.sizeWithoutPadding - CoverSizes59.tileBorder,
      },
    },
    oneSliderOneButton: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
      },
    },
    twoSliders: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
      },
    },
    oneSliderTwoButtons: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
      },
    },
    oneSliderOneRocker: {
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
        },
      },
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
      },
    },
    fourButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
      },
    },
  },
  vertical: {
    oneButton: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 2 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: CoverSizes59.sizeWithoutPadding - CoverSizes59.tileBorder,
      },
    },
    rocker: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: CoverSizes59.sizeWithoutPadding - CoverSizes59.tileBorder, //198
        },
      },
      canvas: {
        width: CoverSizes59.sizeWithoutPadding - CoverSizes59.tileBorder,
      },
    },
    twoButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder, //96.8
      },
    },
    twoRockers: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
        },
      },
      canvas: {
        width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
      },
    },
    oneButtonOneRocker: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
      },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (CoverSizes59.sizeWithoutPadding - CoverSizes59.oneButtonOneRockerSpaceBetweenTiles * 2) / 3 -
            CoverSizes59.tileBorder,
        },
      },
    },
    twoButtonsOneRocker: {
      small: { maxRows: 2 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (CoverSizes59.sizeWithoutPadding - CoverSizes59.twoButtonsOneRockerSpaceBetweenTiles * 2) / 3 -
            CoverSizes59.tileBorder,
        },
      },
      canvas: {
        width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
      },
    },
    oneSlider: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: CoverSizes59.sizeWithoutPadding - CoverSizes59.tileBorder,
      },
    },
    oneSliderOneButton: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
      },
    },
    twoSliders: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
      },
    },
    oneSliderTwoButtons: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
      },
    },
    oneSliderOneRocker: {
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
        },
      },
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
      },
    },
    fourButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: (CoverSizes59.sizeWithoutPadding - CoverSizes59.spaceForSeparationLine) / 2 - CoverSizes59.tileBorder,
      },
    },
  },
};

const LimitChar70: ITextFieldProps = {
  horizontal: {
    oneButton: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 2 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: CoverSizes70.sizeWithoutPadding - CoverSizes70.tileBorder,
      },
    },
    rocker: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (CoverSizes70.sizeWithoutPadding - CoverSizes70.oneRockerSpaceBetweenTiles * 2) / 3 -
            CoverSizes70.tileBorder,
        },
      },
      canvas: {
        width:
          (CoverSizes70.sizeWithoutPadding - CoverSizes70.oneRockerSpaceBetweenTiles * 2) / 3 - CoverSizes70.tileBorder,
      },
    },
    twoButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: CoverSizes70.sizeWithoutPadding - CoverSizes70.tileBorder,
      },
    },
    twoRockers: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (CoverSizes70.sizeWithoutPadding - CoverSizes70.twoRockersSpaceBetweenTiles * 2) / 3 -
            CoverSizes70.tileBorder,
        },
      },
      canvas: {
        width:
          (CoverSizes70.sizeWithoutPadding - CoverSizes70.twoRockersSpaceBetweenTiles * 2) / 3 -
          CoverSizes70.tileBorder,
      },
    },
    oneButtonOneRocker: {
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (CoverSizes70.sizeWithoutPadding - CoverSizes70.twoButtonsOneRockerSpaceBetweenTiles * 2) / 3 -
            CoverSizes70.tileBorder,
        },
      },
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
      },
    },
    twoButtonsOneRocker: {
      small: { maxRows: 2 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
        },
      },
      canvas: {
        width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
      },
    },
    oneSlider: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: CoverSizes70.sizeWithoutPadding - CoverSizes70.tileBorder,
      },
    },
    oneSliderOneButton: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
      },
    },
    twoSliders: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
      },
    },
    oneSliderTwoButtons: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
      },
    },
    oneSliderOneRocker: {
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
        },
      },
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
      },
    },
    fourButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
      },
    },
  },
  vertical: {
    oneButton: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 2 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: CoverSizes70.sizeWithoutPadding - CoverSizes70.tileBorder,
      },
    },
    rocker: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: CoverSizes70.sizeWithoutPadding - CoverSizes70.tileBorder, //198
        },
      },
      canvas: {
        width: CoverSizes70.sizeWithoutPadding - CoverSizes70.tileBorder,
      },
    },
    twoButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder, //96.8
      },
    },
    twoRockers: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
        },
      },
      canvas: {
        width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
      },
    },
    oneButtonOneRocker: {
      small: { maxRows: 2 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: CoverSizes70.sizeWithoutPadding - CoverSizes70.tileBorder,
      },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
        },
      },
    },
    twoButtonsOneRocker: {
      small: { maxRows: 2 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width:
            (CoverSizes70.sizeWithoutPadding - CoverSizes70.twoButtonsOneRockerSpaceBetweenTiles * 2) / 3 -
            CoverSizes70.tileBorder,
        },
      },
      canvas: {
        width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
      },
    },
    oneSlider: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: CoverSizes70.sizeWithoutPadding - CoverSizes70.tileBorder,
      },
    },
    oneSliderOneButton: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
      },
    },
    twoSliders: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
      },
    },
    oneSliderTwoButtons: {
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
      },
    },
    oneSliderOneRocker: {
      rocker: {
        small: { maxRows: 1 },
        standard: { maxRows: 1 },
        medium: { maxRows: 1 },
        large: { maxRows: 0 },
        xlarge: { maxRows: 0 },
        canvas: {
          width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
        },
      },
      small: { maxRows: 1 },
      standard: { maxRows: 1 },
      medium: { maxRows: 1 },
      large: { maxRows: 0 },
      xlarge: { maxRows: 0 },
      canvas: {
        width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
      },
    },
    fourButtons: {
      small: { maxRows: 2 },
      standard: { maxRows: 2 },
      medium: { maxRows: 1 },
      large: { maxRows: 1 },
      xlarge: { maxRows: 1 },
      canvas: {
        width: (CoverSizes70.sizeWithoutPadding - CoverSizes70.spaceForSeparationLine) / 2 - CoverSizes70.tileBorder,
      },
    },
  },
};

export const LimitChar = new Map<string, ITextFieldProps>([
  ["carat", LimitChar62],
  ["future", LimitChar62],
  ["sidus", LimitChar59],
  ["calle", smallLimitChar],
  ["tpr", LimitChar70],
  ["purestainlesssteel", LimitChar62],
  ["skyniessen", LimitChar54],
  ["buschbalancesi", smallLimitChar],
  ["axcentpur", LimitChar62],
  ["futurelinear", LimitChar62],
  ["solo", LimitChar62],
  ["axcent", LimitChar62],
  ["dynasty", LimitChar62],
]);

export const isHorizontal = (rotate: number) => {
  if ([90, 270].includes(rotate!)) return true;

  return false;
};

/*
  LimitChar62 => 62.5 x 63.5
*/
