import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import React, { RefObject, useState } from "react";
import { useStoreState } from "../../hooks";
import { IElementSize } from "../../interfaces/DTO/IElementSize";
import { IStyleProps } from "../../pages/Configurator/Components/GridLine/GridLine";
import DimensionLine from "../../pages/Configurator/InsertConfiguration/Tiles/DimensionLine";
import { getElementPosition } from "../../pages/Configurator/InsertConfiguration/Tiles/utils";
import "./TooltipDimension.css";
import { IDesignDimensions } from "../../interfaces/IDesignDimensions";
import { EInsertDetailItemType, IInsertDetail } from "../../interfaces/IInsertDetail";
import { getItemsDimensions } from "../../pages/Configurator/InsertConfiguration/Tiles/utils-get-dimensions";
import { DECIMAL_PLACES } from "../../constants/NumberValues";
import { EIconPercentSize } from "../../enum/EIconSize";
import { EFontPixelSize } from "../../enum/EFontSize";

interface IProps {
  children: React.ReactElement;
  coverRef: RefObject<HTMLDivElement>;
  iconRef?: RefObject<HTMLDivElement>;
  elementRef: RefObject<HTMLDivElement | HTMLCanvasElement>;
  styleProps?: IStyleProps;
  isFlipped?: boolean;
  getElementSize?: () => IElementSize;
  enableDimensionLine: boolean;
  enableDimensionBorder: boolean;
  enableStartEndPositions: boolean;
  insertDetail: IInsertDetail;
  indexDetail: string;
  designSizes: IDesignDimensions;
  type?: EInsertDetailItemType;
}

const TooltipDimension: React.FC<IProps> = (props) => {
  const {
    children,
    coverRef,
    elementRef,
    iconRef,
    getElementSize,
    enableDimensionLine,
    enableDimensionBorder,
    enableStartEndPositions,
    insertDetail,
    indexDetail,
    designSizes,
    type,
  } = props;
  const { enableDimensionComponent } = useStoreState((state) => state.app);
  const [showPosition, setShowPosition] = useState<boolean>(false);

  const handleElementSize = () => {
    return getElementSize ? getElementSize : getDefaultSize;
  };

  const getDefaultSize = () => {
    const elementSize: IElementSize = {
      width: elementRef!.current?.offsetWidth!,
      height: elementRef!.current?.offsetHeight!,
    };

    return elementSize;
  };

  const getDimensions = (
    insertDetail: IInsertDetail,
    indexDetail: string,
    designSizes: IDesignDimensions,
    type?: EInsertDetailItemType
  ) => {
    const layoutName = insertDetail?.insertType?.name;
    const actualType = type || (iconRef === undefined ? EInsertDetailItemType.Text : EInsertDetailItemType.Icon);

    const actualDimensions = getItemsDimensions(
      indexDetail,
      layoutName,
      designSizes,
      insertDetail,
      actualType,
      EIconPercentSize.MEDIUM,
      EFontPixelSize.STANDARD
    );

    return {
      indexDetail,
      height: actualDimensions?.actualHeightMm ?? null,
      width: actualDimensions?.actualWidthMm ?? null,
      startX: actualDimensions?.actualStartXMm ?? null,
      startY: actualDimensions?.actualStartYMm ?? null,
      endX: actualDimensions?.actualEndXMm ?? null,
      endY: actualDimensions?.actualEndYMm ?? null,
      posX: actualDimensions?.actualPosXMm ?? null,
      posY: actualDimensions?.actualPosYMm ?? null,
    };
  };

  const formatValue = (value: number) => {
    return (Math.round(value * 100) / 100).toFixed(DECIMAL_PLACES);
  };

  const dimensions = getDimensions(insertDetail, indexDetail, designSizes, type);

  return (
    <>
      <Tooltip
        open={enableDimensionComponent && showPosition}
        arrow
        placement="right"
        onMouseOver={() => {
          setShowPosition(true);
        }}
        onMouseLeave={() => {
          setShowPosition(false);
        }}
        title={
          <>
            <Table
              size="small"
              className="tableDimension"
            >
              <TableHead>
                <TableRow sx={{}}>
                  <TableCell
                    size="small"
                    sx={{ fontSize: "10px" }}
                  >
                    Measure
                  </TableCell>
                  <TableCell
                    size="small"
                    sx={{ fontSize: "10px" }}
                  >
                    mm
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!enableStartEndPositions && (
                  <>
                    <TableRow>
                      <TableCell size="small">
                        <Typography
                          variant="caption"
                          color="primary"
                        >
                          width:
                        </Typography>
                      </TableCell>
                      <TableCell size="small">{dimensions?.width?.toFixed(DECIMAL_PLACES)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="caption"
                          color="primary"
                        >
                          height:
                        </Typography>
                      </TableCell>
                      <TableCell>{dimensions?.height?.toFixed(DECIMAL_PLACES)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="caption"
                          color="primary"
                        >
                          x:
                        </Typography>
                      </TableCell>
                      <TableCell>{formatValue(dimensions?.posX!)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="caption"
                          color="primary"
                        >
                          y:
                        </Typography>
                      </TableCell>
                      <TableCell>{formatValue(dimensions?.posY!)}</TableCell>
                    </TableRow>
                  </>
                )}

                {enableStartEndPositions && (
                  <>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="caption"
                          color="primary"
                        >
                          start x:
                        </Typography>
                      </TableCell>
                      <TableCell>{dimensions?.startX?.toFixed(DECIMAL_PLACES)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="caption"
                          color="primary"
                        >
                          start y:
                        </Typography>
                      </TableCell>
                      <TableCell>{dimensions?.startY?.toFixed(DECIMAL_PLACES)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="caption"
                          color="primary"
                        >
                          end x:
                        </Typography>
                      </TableCell>
                      <TableCell>{dimensions?.endX?.toFixed(DECIMAL_PLACES)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="caption"
                          color="primary"
                        >
                          end y:
                        </Typography>
                      </TableCell>
                      <TableCell>{dimensions?.endY?.toFixed(DECIMAL_PLACES)}</TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </>
        }
      >
        <div
          ref={iconRef}
          style={{
            ...props.styleProps,
          }}
        >
          <div
            className={`${props.isFlipped ? "isFlipped" : ""}`}
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              ...{
                cursor: `${enableDimensionComponent && showPosition ? "pointer" : ""}`,
                // border: `${enableDimensionComponent && showPosition && enableDimensionBorder ? "1px solid red" : ""}`,
                outline: `${
                  enableDimensionComponent && showPosition && enableDimensionBorder ? "0.2px solid red" : ""
                }`,
              },
            }}
          >
            <DimensionLine
              show={enableDimensionComponent && showPosition && enableDimensionLine}
              dimensions={getElementPosition(props.coverRef!, elementRef, handleElementSize())!}
              actualDimensions={dimensions}
            />
            {children}
          </div>
        </div>
      </Tooltip>
    </>
  );
};

export default TooltipDimension;
