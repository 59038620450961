import { Grid } from "@mui/material";
import TileConfiguration from "../TileConfiguration";
import StandardTile from "../StandardTile";
import { ITileButtonProps } from "../../../../../interfaces/ITileButtonProps";
import TilePreviewConfiguration from "../TilePreviewConfiguration";
import { ITileDimension } from "../../../../../interfaces/ITileDimension";
import { EFontPixelSize } from "../../../../../enum/EFontSize";
import { EIconPercentSize, EIconPixelSize } from "../../../../../enum/EIconSize";
import { isHorizontal, LimitChar } from "../limitChar";
import { useRef } from "react";

const OneButton: React.FC<ITileButtonProps> = (props) => {
  const border = props.isPreview ? "tile-border-preview" : "tile-border";

  const tileDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_CONTROL,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.SMALL,
    iconMaxPercentage: EIconPercentSize.XLARGE,
    fontSizeDefault: EFontPixelSize.SMALL,
    fontSizeMin: EFontPixelSize.SMALL,
    fontSizeMax: EFontPixelSize.SMALL,
    textareaMaxHeight: 42,
    spaceBetweenIconAndTextArea: 4,
    isFunction: false,
  };

  const textFieldProps = LimitChar.get(props.designCode)!;
  const coverRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        ref={coverRef}
        data-designcode={props.designCode}
      >
        <StandardTile designCode={props.designCode}>
          <Grid
            className={border}
            container
          >
            {props.isPreview ? (
              <TilePreviewConfiguration
                tileDimension={tileDimension}
                designCode={props.designCode}
                textLabelSizes={
                  isHorizontal(props.insertDetail.rotate!)
                    ? textFieldProps.horizontal.oneButton
                    : textFieldProps.vertical.oneButton
                }
                tileName="oneButton"
                isRocker={false}
                cover={props.cover}
                insertDetail={props.insertDetail!}
                indexDetail="1"
              />
            ) : (
              <TileConfiguration
                isRocker={false}
                isHorizontal={props.isHorizontal}
                designCode={props.designCode}
                textLabelSizes={
                  isHorizontal(props.insertDetail.rotate!)
                    ? textFieldProps.horizontal.oneButton
                    : textFieldProps.vertical.oneButton
                }
                tileName="oneButton"
                tileDimension={tileDimension}
                insertDetail={props.insertDetail!}
                setInsertDetail={props.setInsertDetail!}
                cover={props.cover}
                indexDetail="1"
                coverRef={coverRef}
                canManageRef={props.canManageRef}
              />
            )}
          </Grid>
        </StandardTile>
      </div>
    </>
  );
};

export default OneButton;
