import { SetStateAction, useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { omit, isEmpty } from "lodash";
import { IProductDesignSummaryDto } from "../../../../interfaces/DTO/IProductDesignSummaryDto";
import { IProjectSummaryDto } from "../../../../interfaces/DTO/IProjectSummaryDto";
import "../ProjectSummary.css";
import { useTranslation } from "react-i18next";
import { useStoreActions, useStoreState } from "../../../../hooks";
import { Row } from "../Row/Row";
import { IProductDesignSummaryResponse } from "../../../../interfaces/Project/Response/ProductDesignSummaryResponse";

interface ProjectSummaryTable {
  setCoverCost: React.Dispatch<SetStateAction<number>>;
  productDesignSummary: IProductDesignSummaryDto[];
  projects: IProductDesignSummaryResponse[];
  refeshScreen: () => void;
}

interface IExtendedProjectSummaryDto extends IProjectSummaryDto {
  projectsToShow: IProductDesignSummaryResponse[];
}

const ProjectSummaryTable: React.FC<ProjectSummaryTable> = (props) => {
  const { setCoverCost, productDesignSummary, refeshScreen, projects } = props;
  const { t } = useTranslation();
  const { projectsSummary, selectedProjectsSummary } = useStoreState((state) => state.designs.data);
  const { currentProjectId } = useStoreState((state) => state.designs.configuration);
  const { thunkGetProductDesignDataByProjectId, setSelectedProjectsWithProductsIds } = useStoreActions(
    (actions) => actions.designs
  );
  const [selectedProjectInfo, setSelectedProjectInfo] = useState<IExtendedProjectSummaryDto[]>([]);

  useEffect(() => {
    if (currentProjectId) {
      thunkGetProductDesignDataByProjectId(currentProjectId!);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(projectsSummary) && !isEmpty(selectedProjectsSummary)) {
      const selectedProjects = projectsSummary
        .filter((item) => selectedProjectsSummary.includes(item.id))
        .map((item) => ({
          ...item,
          projectsToShow: projects.filter((_item) => _item.project.id === item.id),
        }));
      setSelectedProjectInfo(selectedProjects);
      const selectedProjectsWithProductsIds = selectedProjects.reduce<string[]>((accu, item) => {
        return !isEmpty(item.projectsToShow) ? [...accu, item.id] : accu;
      }, []);
      setSelectedProjectsWithProductsIds(selectedProjectsWithProductsIds);
    }
  }, [projectsSummary, selectedProjectsSummary]);

  return (
    <TableContainer sx={{ border: "1px solid #f5f5f5", borderRadius: "6px", fontFamily: "BJEAverta" }}>
      <Table
        data-projectid={currentProjectId}
        aria-label="collapsible table"
      >
        <TableHead>
          <TableRow>
            <TableCell
              className="projectSummaryTable_headercell"
              width={459}
              align="left"
            >
              {t("DesignSummary_HeaderDetails")}
            </TableCell>
            <TableCell
              className="projectSummaryTable_headercell"
              width={150}
              align="left"
            >
              {t("numberOfProducts")}
            </TableCell>
            <TableCell
              className="projectSummaryTable_headercell"
              width={122}
              align="left"
            >
              {t("DesignSummary_Status")}
            </TableCell>
            <TableCell
              className="projectSummaryTable_headercell"
              width={119}
              align="center"
            >
              {t("table.head_cell.label_action")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedProjectInfo.map((item) => {
            return (
              <Row
                key={item.name}
                row={omit(item, ["projectsToShow"])}
                projects={item.projectsToShow}
                setCoverCost={setCoverCost}
                productDesignSummary={productDesignSummary}
                refeshScreen={refeshScreen}
                projectId={item.id}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectSummaryTable;
